const storage: any = {
    // 设置缓存
    localSet: (name: string, data: any) => {
        localStorage.removeItem(name);
        localStorage.setItem(name, JSON.stringify(data));
    },
    // 获取缓存
    localGet: (name: string) => {
        const strorageSelectedFilter = localStorage.getItem(name);
        if (strorageSelectedFilter) {
            return JSON.parse(strorageSelectedFilter);
        }
    },
    // 清除缓存
    localRemove: (name: string) => {
        localStorage.removeItem(name);
    },
};
export default storage;
