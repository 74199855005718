import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import storage from '@/utils/storage.ts';
import { errorMessage } from './message';

interface IHttpRequest {
  baseUrl: string;
}

class HttpRequest implements IHttpRequest {
  public baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  public getInsideConfig(): AxiosRequestConfig {
    const config = {
      baseURL: this.baseUrl,
    };
    return config;
  }
  public interceptors(instance: AxiosInstance) {
    instance.interceptors.response.use((response) => {
      return response.data;
    }, (error) => {
      switch (error.response.status) {
        case 101:
            break;
        case 401:
            error.message = '登录失效,请重新登录!';
            storage.localRemove('version_user_info'); // 清除用户信息
            setTimeout(() => { // 登录
              location.href = '#/login';
            }, 500);
            break;
        case 500:
            error.message = '服务内部异常!';
            break;
        case 504:
            error.message = '网关超时!';
            break;
        default:
            error.message = '未知错误';
            break;
      }
      errorMessage(error.message);
      return Promise.reject(error);
    });
    instance.interceptors.request.use((config) => { // 请求拦截器
      const token = storage.localGet('version_user_info');
      if (token && location.pathname !== '/login') {
        config.headers.Authorization = `Bearer ${token.token}`;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }
  public request(options: AxiosRequestConfig): Promise<any> {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance);
    return instance(options);
  }
  public get(options: AxiosRequestConfig): Promise<any> {
    const newOptions = Object.assign(options, { method: 'get' });
    return this.request(newOptions);
  }
  public post(options: AxiosRequestConfig): Promise<any> {
    const newOptions = Object.assign(options, { method: 'post' });
    return this.request(newOptions);
  }
}

export default HttpRequest;
